<template>
  <div class="my-metaverse-container w-full my-5">
    <h1
      class="title-st text-5xl uppercase leading-normal mt-4 mb-2 text-gray-200"
    >
      My metaverses
    </h1>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MyMetaverseView",
  data() {
    return {};
  },
});
</script>
