<template>
  <header>
    <nav
      class="px-8 py-6 md:p-0 md:bg-opacity-50 bg-gradient-to-b from-[#030408] to-[rgba(3,4,8,0)] md:to-[rgba(3,4,8,0)]"
    >
      <div
        class="flex flex-wrap justify-end items-center mx-auto max-w-screen-xl md:bg-black md:bg-opacity-30 md:justify-start md:p-6"
      >
        <div
          :class="['flex flex-wrap justify-between', 'md:flex-col md:flex-1']"
        >
          <div
            :class="['adaptive-menu hidden md:flex justify-end cursor-pointer']"
          >
            <div
              @click="toggleMenu"
              class="burger flex px-2 py-[10px] flex-col w-9 h-9 bg-white justify-between"
            >
              <i class="flex border-t-[2px] border-black"></i>
              <i class="flex border-t-[2px] border-black"></i>
              <i class="flex border-t-[2px] border-black"></i>
            </div>
          </div>
          <div
            :class="[
              'w-full h-full items-center flex flex-wrap justify-end',
              'md:flex-col md:mt-5',
              !showMenu ? 'md:hidden' : '',
            ]"
          >
            <a
              target="_blank"
              href="https://veverse.com/"
              :class="[
                'text-white font-medium rounded-lg text-sm px-1 py-2 lg:py-2 mx-3',
                'md:flex md:w-full md:justify-center md:hover:bg-black whitespace-nowrap',
              ]"
            >
              About
            </a>
            <a
              target="_blank"
              href="https://veverse.com/blog"
              :class="[
                'text-white font-medium rounded-lg text-sm px-1 py-2 lg:py-2 mx-3',
                'md:flex md:w-full md:justify-center md:hover:bg-black whitespace-nowrap',
              ]"
            >
              News
            </a>
            <a
              target="_blank"
              href="#"
              :class="[
                'text-white font-medium rounded-lg text-sm px-1 py-2 lg:py-2 mx-3',
                'md:flex md:w-full md:justify-center md:hover:bg-black whitespace-nowrap',
              ]"
            >
              For Developers
            </a>
            <a
              target="_blank"
              href="https://veverse.com/contact-us"
              :class="[
                'text-white font-medium rounded-lg text-sm px-1 py-2 lg:py-2 mx-3',
                'md:flex md:w-full md:justify-center md:hover:bg-black whitespace-nowrap',
              ]"
            >
              Contact Us
            </a>
            <button
              :class="[
                'bg-white border-r-2 text-[#030408] font-medium text-xs px-5 py-3 ml-3',
                'md:ml-0 mt-1',
              ]"
              @click.prevent="logOut"
            >
              Sign Out
            </button>
          </div>
          <!--          <button-->
          <!--            data-collapse-toggle="mobile-menu-2"-->
          <!--            type="button"-->
          <!--            class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"-->
          <!--            aria-controls="mobile-menu-2"-->
          <!--            aria-expanded="false"-->
          <!--          >-->
          <!--            <span class="sr-only">Open main menu</span>-->
          <!--            <svg-->
          <!--              class="w-6 h-6"-->
          <!--              fill="currentColor"-->
          <!--              viewBox="0 0 20 20"-->
          <!--              xmlns="http://www.w3.org/2000/svg"-->
          <!--            >-->
          <!--              <path-->
          <!--                fill-rule="evenodd"-->
          <!--                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"-->
          <!--                clip-rule="evenodd"-->
          <!--              ></path>-->
          <!--            </svg>-->
          <!--            <svg-->
          <!--              class="hidden w-6 h-6"-->
          <!--              fill="currentColor"-->
          <!--              viewBox="0 0 20 20"-->
          <!--              xmlns="http://www.w3.org/2000/svg"-->
          <!--            >-->
          <!--              <path-->
          <!--                fill-rule="evenodd"-->
          <!--                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"-->
          <!--                clip-rule="evenodd"-->
          <!--              ></path>-->
          <!--            </svg>-->
          <!--          </button>-->
        </div>
        <!--        <div-->
        <!--          class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"-->
        <!--          id="mobile-menu-2"-->
        <!--        >-->
        <!--          <ul-->
        <!--            class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0"-->
        <!--          >-->
        <!--            <li>-->
        <!--              <a-->
        <!--                href="#"-->
        <!--                class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"-->
        <!--                aria-current="page"-->
        <!--                >Home</a-->
        <!--              >-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a-->
        <!--                href="#"-->
        <!--                class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"-->
        <!--                >Company</a-->
        <!--              >-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a-->
        <!--                href="#"-->
        <!--                class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"-->
        <!--                >Marketplace</a-->
        <!--              >-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a-->
        <!--                href="#"-->
        <!--                class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"-->
        <!--                >Features</a-->
        <!--              >-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a-->
        <!--                href="#"-->
        <!--                class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"-->
        <!--                >Team</a-->
        <!--              >-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a-->
        <!--                href="#"-->
        <!--                class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"-->
        <!--                >Contact</a-->
        <!--              >-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </nav>
  </header>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "TopHeader",
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    logOut() {
      this.logout();
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
});
</script>

<style scoped></style>
