<template>
  <h1>Error</h1>
</template>

<script>
export default {
  name: "ErrorView",
};
</script>

<style scoped></style>
